import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import classNames from 'classnames';
import { autocomplete } from '$api/postcode';
import { Icon } from '@/components/atoms/Icon';
import { Button, DesignType } from '@/components/atoms/Button';

import styles from './index.module.scss';

export enum PostcodeAutocompleteV2Variations {
  withButton = 'withButton',
  withoutButton = 'withoutButton',
}

interface PostcodeAutocompleteV2Props {
  readonly errorMessage?: string;
  readonly onChange?: (e: any) => void;
  readonly onCtaButtonClick?: (e: any) => void;
  readonly onPostcodeSelect?: (postcode: string) => void;
  readonly useAutocomplete?: boolean;
  readonly className?: string;
  readonly inputClassName?: string;
  readonly translations: {
    readonly textPostcode: string;
  };
  readonly variation?: PostcodeAutocompleteV2Variations;
  readonly pinIcon?: {
    readonly id: string;
    readonly width?: number;
    readonly height?: number;
  };
  readonly loupeIcon?: {
    readonly id: string;
    readonly width?: number;
  };
}

interface Suggestion {
  name: string;
}

function PostcodeAutocompleteV2({
  onPostcodeSelect,
  onChange,
  onCtaButtonClick = () => {},
  variation = PostcodeAutocompleteV2Variations.withoutButton,
  errorMessage,
  useAutocomplete,
  className,
  inputClassName,
  translations,
  pinIcon = {
    id: 'travel/marker-pin-04',
    width: 24,
    height: 24,
  },
  loupeIcon = {
    id: 'general/search-sm',
    width: 16,
  },
}: PostcodeAutocompleteV2Props): JSX.Element {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [focusedInput, setFocusedInput] = useState('');
  const [lastSelected, setLastSelected] = useState('');
  const [errors, setErrors] = useState({ postcode: '' });

  const fetchPostcodeSuggestions = useCallback(
    debounce(async (postcode) => {
      if (postcode === lastSelected) {
        return;
      }
      setIsLoading(true);
      try {
        const response = await autocomplete(postcode);
        const data = (get(response, 'data.result') || []).map((pc: string) => ({ name: pc }));
        setSuggestions(data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }, 300),
    [lastSelected]
  );

  useEffect(() => {
    if (errorMessage) {
      setErrors({ postcode: errorMessage });
    }
  }, [errorMessage]);

  useEffect(() => {
    if (useAutocomplete) {
      if (inputValue) {
        fetchPostcodeSuggestions(inputValue);
      } else {
        setSuggestions([]);
      }
    } else {
      setSuggestions(inputValue ? [{ name: inputValue }] : []);
    }
    return () => fetchPostcodeSuggestions.cancel();
  }, [inputValue, fetchPostcodeSuggestions, useAutocomplete]);

  const handleFocus = (inputName: string) => {
    setFocusedInput(inputName);
  };

  const handleBlur = () => {
    setFocusedInput('');
  };

  const handleSelectSuggestion = useCallback(
    (suggestion: Suggestion) => {
      setLastSelected(suggestion.name);
      setInputValue(suggestion.name);
      setSuggestions([]);
      if (onPostcodeSelect) {
        onPostcodeSelect(suggestion.name);
      }
    },
    [onPostcodeSelect]
  );

  useEffect(() => {
    if (suggestions.length === 1) {
      handleSelectSuggestion(suggestions[0]);
    }
  }, [suggestions, handleSelectSuggestion]);

  const isCtaButtonEnabled = variation === PostcodeAutocompleteV2Variations.withButton;

  return (
    <div className={classNames(styles.autocompleteContainer, className || '')}>
      <Icon
        id={pinIcon.id}
        height={pinIcon.height}
        width={pinIcon.width}
        legacy={false}
        className={classNames(styles.icon, { [styles.iconFocused]: focusedInput === 'postcode' }, 'pinIcon')}
      />
      <input
        type="text"
        name="postcode"
        value={inputValue}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
          setInputValue(e.target.value);
          if (errors.postcode) {
            setErrors((prevErrors) => ({ ...prevErrors, postcode: '' }));
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onCtaButtonClick(inputValue);
          }
        }}
        onFocus={() => handleFocus('postcode')}
        onBlur={handleBlur}
        className={classNames(
          styles.input,
          inputClassName,
          errors.postcode && 'inputError',
          isCtaButtonEnabled && styles.withCtaButton
        )}
        placeholder={translations.textPostcode}
        data-testid="input-postcode"
      />

      {isCtaButtonEnabled && (
        <Button
          disabled={!lastSelected}
          className={classNames(styles.ButtonCta, 'postcodeCtaButton')}
          onClick={() => onCtaButtonClick(inputValue)}
          designType={DesignType.CTA_DARK}
          size="M"
          iconOnly
          iconId={loupeIcon.id}
          iconSize={loupeIcon.width}
        />
      )}

      {errors.postcode && (
        <div
          className={classNames('errorMessage', styles.errorMessage, { [styles.visible]: errors.postcode })}
        >
          {errors.postcode}
        </div>
      )}
      {useAutocomplete && suggestions.length > 0 && (
        <ul className={styles.suggestionsList}>
          {isLoading && <li className={styles.loadingItem} />}
          {suggestions.map((suggestion, index) => (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={styles.suggestionItem}
              onClick={() => handleSelectSuggestion(suggestion)}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="button"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSelectSuggestion(suggestion);
                }
              }}
            >
              {suggestion.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

PostcodeAutocompleteV2.displayName = 'PostcodeAutocompleteV2';
export default PostcodeAutocompleteV2;
